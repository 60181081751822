import React from "react"
import { Link } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import Collapsible from 'react-collapsible';
import Layout from "../components/layout"
import Cta from "../components/cta"
import Seo from "../components/seo"
import Hero from "../components/hero"
import ArrowRight from "../components/svg/arrowRight"
import ChevronDown from "../components/svg/chevronDown"

const GatsbyPage = () => (
    <Layout>
        <Seo 
            title="React.js developers" 
            description="React developers in Sydney and Canberra. We develop web applications using the React.js Javascript library."
            pathname="/gatsby-developer/"
        />

        <Hero
            h1="Gatsby Developers Sydney"  
            h2="We develop static and modern JAMstack websites using Gatsy.js"
        />  

        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="below-hero"
                        data-sal="slide-right" 
                        data-sal-easing="ease-in-out"
                        data-sal-duration="400"
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">10+</span>
                            <p>We've built more than 10 websites, and many with CMS', using the Gatsby.js React library.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">Static = yay!</span>
                            <p>Static sites hosted on S3, with CDN, means a super fast website that's hassle free &amp; cheap to run.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">CMS</span>
                            <p>For websites that require a CMS we go headless with Gatsby using products like Contentful.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3
                            >An experienced Gatsby team</h3>
                        </div>

                        <div className="col-67 long-text"
                            data-sal="slide-up" 
                            data-sal-easing="ease-in-out"
                            data-sal-duration="400"
                        >
                            <p>
                                We develop websites for startups and larger organisations using the popular Gatsby React-based Javascript library.
                            </p>
                            <p>
                                Why Gatsby?
                            </p>
                            <p>
                                Well, we love fast loading websites and a great developer experience. We're also very experienced using it and have many Gatsby websites in production for both small and large companies.
                            </p>
                            <p>
                                We do all of our web development work onshore in Australia and offer complementary services like:
                            </p>
                            <ul>
                                <li>web design</li>
                                <li><Link to="/react-developers-sydney-canberra/">React development</Link></li>
                                <li><Link to="/headless-cms/">headless CMS</Link> integration</li>
                                <li>full stack application development</li>   
                            </ul> 
                            <p>
                                If you're looking for a modern website that loads quickly and is developed on a popular well supported framework we're sure Gatsby will be a great fit for your website. 
                            </p>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Contact us
                                        <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all work
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                FAQs
                            </h5>
                        </div>
                        <div className="col-67">
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    How can I find out more about Gatsby? <ChevronDown />
                                </>
                                }
                            >
                                <p>It's best to check out the 'why' page on the official <a href="https://www.gatsbyjs.com/why-gatsby/">Gatsby website</a></p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Where do you host Gatsby websites? <ChevronDown />
                                </>
                                }
                            >
                                <p>We mostly host static websites out of an AWS S3 bucket behind a CDN. This ensures your website is rediculously cheap to host, is secure, and loads quickly.</p>
                                <p>We have also hosted Gatsby websites on Netlify, which offers a hassle free hosting solution with some great free features built in.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you offer CMS development? <ChevronDown />
                                </>
                                }
                            >
                                <p>Yes. Our preference is to use Contentful (a headless CMS) or Storyblok. We've also used Netlify CMS, which is a free open source CMS project.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    What if my website has already been designed? <ChevronDown />
                                </>
                                }
                            >
                                <p>That's ok</p>
                                <p>If you need us to design your website we'd love to help. However, if you're site has already been designed we're happy to work from supplied Figma / Sketch / Adobe XD files and develop the website for you.</p>
                            </Collapsible>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <Cta 
            text="Get a web design quote and / or a Gatsby web development quote from a local onshore Australian design & development team"
        />
    </Layout>
)

export default GatsbyPage